import React from 'react';
import Dashboard from './pages/overview';
import Plaza from './pages/plaza';
import ViewPlaza from './pages/plaza/viewPlaza';
import Settings from './pages/settings/Settings';
import Reports from './pages/reports';
import Pricing from './pages/pricing';
import Settlement from './pages/settlements';
import Archives from './pages/archives';
import Banking from './pages/banking';

export default [
  { path: '/banking', name: 'Banking', component: Banking, exact: true },
  { path: '/settlement', name: 'Settlements', component: Settlement, exact: true },
  { path: '/archives', name: 'Archives', component: Archives, exact: true },
  { path: '/plaza', name: 'Plaza', component: Plaza, exact: true },
  { path: '/pricing', name: 'Pricing', component: Pricing, exact: true },
  { path: '/plaza/:plaza', name: 'Plaza', component: ViewPlaza, exact: true },
  { path: '/settings', name: 'Settings', component: Settings, exact: true },
  { path: '/reports', name: 'Reports', component: Reports, exact: true },
  { path: '/', name: 'Dashboard', component: Dashboard, exact: true },
];
