import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Header from '../../components/layout/header';
import useAxios from 'axios-hooks';
import config from '../../config';
import constants from '../../constants';
import { Table, DatePicker, Typography, Row, Col, Select } from 'antd';
import { toaster } from 'evergreen-ui';
import axios from 'axios';
import moment from 'moment';

const { RangePicker } = DatePicker;
export default function Archives() {
  const [{ data, loading, error }] = useAxios({
    url: `${config}/api/v1/settlements`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const [{ data: pzData, loading: loadingPlaza, error: errorLoadingPlaza }] = useAxios({
    url: `${config}/api/v1/plaza`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const [settlementsData, setSettlementsData] = useState([]);
  const [localPlazas, setLocalPlazas] = React.useState([]);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    if (data) {
      setTempData(data);
      setSettlementsData(data.filter((item) => localPlazas.includes(item?.plaza.name)));
    }
  }, [data]);

  async function getPlazas() {
    const nP = JSON.parse(sessionStorage.getItem('plazas'));
    let npList = [];
    nP.map((item) => {
      npList.push(item?.name);
    });

    setLocalPlazas(npList);
  }

  const [plaza, setPlaza] = useState([]);

  useEffect(() => {
    getPlazas();
  }, []);

  const [l, setLoading] = useState(false);

  const columns = [
    {
      title: 'Shift ID',
      dataIndex: 'shift',
      render: (t) => <Typography.Text>{t?.shiftID}</Typography.Text>,
    },
    {
      title: 'Plaza',
      dataIndex: 'plaza',
      render: (t) => <Typography.Text>{t?.name}</Typography.Text>,
    },
    {
      title: 'Cashier',
      dataIndex: 'shift',
      render: (t) => <Typography.Text>{t?.user?.fullName}</Typography.Text>,
    },

    {
      title: 'POS Revenue',
      render: (t) => (
        <Typography.Text>&cent;{t?.shift?.cash || t?.shift?.totalAmount}</Typography.Text>
      ),
    },
    {
      title: 'Manual ',
      render: (t) => (
        <Typography.Text>&cent;{t?.shift?.manual || t?.shift?.manual}</Typography.Text>
      ),
    },
    {
      title: 'Total',
      render: (t) => (
        <Typography.Text>
          &cent;
          {t?.shift?.cash
            ? t?.shift?.cash + t?.shift?.manual
            : t?.shift?.totalAmount + t?.shift?.manual}
        </Typography.Text>
      ),
    },
    {
      title: 'Amount Settled',
      dataIndex: 'amount',
      render: (t) => <Typography.Text>&cent;{t}</Typography.Text>,
    },
    {
      title: 'Arrears/Overage',
      dataIndex: 'arrears',
      render: (t) => (
        <Typography.Text style={{ color: t > 0 ? 'red' : 'green' }}>
          &cent;{t.toFixed(2)}
        </Typography.Text>
      ),
    },
    {
      title: 'Shift End',
      dataIndex: 'shift',
      render: (t) => (
        <Typography.Text>{moment(t.endTime).format('MMM, Do YYYY hh:mm A')}</Typography.Text>
      ),
    },
    {
      title: 'Settled On',

      render: (t) => (
        <Typography.Text
          style={{ color: moment(t?.createdAt).isBefore(t?.shift?.endTime) ? 'red' : 'green' }}
        >
          {moment(t?.createdAt).format('MMM, Do YYYY hh:mm A')}
        </Typography.Text>
      ),
    },
    {
      title: 'Settled By',

      render: (t) => (
        <Typography.Text
        // style={{  }}
        >
          {t?.settledBy?.name}
        </Typography.Text>
      ),
    },
  ];

  let plazaData =
    pzData && localPlazas.length > 0
      ? pzData.filter((item) => localPlazas.includes(item?.name))
      : pzData;

  useEffect(() => {
    let npList = [];
    if (pzData && localPlazas.length === 0) {
      pzData.map((item) => {
        npList.push(item?.name);
      });
      setLocalPlazas(npList);
    }
  }, [pzData]);

  return (
    <Fragment>
      <Header
        title="Settlement Archives"
        additional={() => (
          <Row gutter={[16]}>
            <Col>
              <Select
                style={{ width: '200px' }}
                placeholder="Please select"
                defaultValue={[]}
                showSearch
                onChange={(classifications) => {
                  setPlaza(classifications);
                  //   setFormData({
                  //     ...formData,
                  //  //   classifications,
                  //   });

                  // console.log(classifications);
                  setSettlementsData(
                    tempData.filter((item) => item?.plaza.name === classifications),
                  );

                  console.log(settlementsData);
                }}
              >
                {plazaData &&
                  plazaData.map((classification) => (
                    <Select.Option value={classification.name}>{classification.name}</Select.Option>
                  ))}
              </Select>
            </Col>
            <Col>
              <RangePicker
                onChange={async (d, ds) => {
                  setLoading(true);
                  try {
                    const n = await axios.get(
                      `${config}/api/v1/settlements?startDate=${ds[0]}&endDate=${ds[1]}`,
                      {
                        headers: {
                          token: constants.token,
                          admintoken: sessionStorage.getItem('admintoken'),
                        },
                      },
                    );
                    console.log(plazaData);
                    setSettlementsData(
                      n.data.filter((item) => localPlazas.includes(item?.plaza.name)),
                    );
                    setTempData(n.data);
                  } catch (e) {
                    toaster.danger('Error', { description: e.message });
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </Col>
          </Row>
        )}
      />
      <div style={{ padding: '30px 240px' }}>
        <Table
          pagination={false}
          columns={columns}
          dataSource={settlementsData.reverse()}
          loading={loading || l}
        />
      </div>
    </Fragment>
  );
}
