import React from 'react';
import { SideSheet } from 'evergreen-ui';
import useAxios from 'axios-hooks';
import { Select, Form } from 'antd';
import { toaster } from 'evergreen-ui';
import constants from '../../../constants';
import MultiSelect from 'react-multi-select-component';
import config from '../../../config';

function EditUser({ onCloseComplete, isShown, plazas, user }) {
  console.log('This is user', user);
  const [formData, setFormData] = React.useState({
    name: user.name,
    email: user.email,
    password: user.password,
    access: user?.access,
    plazas: [],
  });

  React.useEffect(() => {
    if (user.plazas) {
      const transformedPlazas = user.plazas.map((plaza) => {
        return { label: plaza.name, value: plaza._id };
      });

      setFormData({
        name: user.name,
        email: user.email,
        password: user.password,
        plazas: transformedPlazas,
      });
    }
  }, [user]);

  const [{ loading }, editUser] = useAxios(
    {
      url: `${config}/api/v1/edit-administrator`,
      method: 'post',
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },
    { manual: true },
  );

  const transformedPlazas = plazas.map((plaza) => {
    return { label: plaza.name, value: plaza._id };
  });

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ padding: '30px' }}>
        <h3>Edit Administrator</h3>

        {!user ? null : (
          <div style={{ marginTop: '50px' }}>
            <div className="mt-10 sm:mt-0">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const plazas = formData.plazas.map((plaza) => plaza.value);
                    editUser({
                      data: {
                        ...formData,
                        administrator: user._id,
                        plazas,
                      },
                    })
                      .then(() => {
                        toaster.success('Request is successful', {
                          description: 'User has been edited successfully',
                        });
                        onCloseComplete();
                      })
                      .catch((error) => {
                        toaster.danger('Request failed', {
                          description:
                            error.response && error.response.data
                              ? error.response.data.message
                              : 'Unknown error',
                        });
                      });
                  }}
                >
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-12 sm:col-span-3">
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Name
                        </label>
                        <input
                          required
                          value={formData.name}
                          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                          id="first_name"
                          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium leading-5 text-gray-700">
                          Email Address
                        </label>
                        <input
                          required
                          value={formData.email}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          type="email"
                          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium leading-5 text-gray-700">
                          Password
                        </label>
                        <input
                          required
                          value={formData.password}
                          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                          type="password"
                          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium leading-5 text-gray-700">
                          Role
                        </label>
                        <Form>
                          <Form.Item initialValue={formData.access}>
                            <Select
                              className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                              placeholder={formData.access}
                              value={formData.access}
                              onChange={(e) => {
                                setFormData({ ...formData, access: e });
                              }}
                              // style={{}}
                            >
                              <Select.Option value="manager">Tolls Manager</Select.Option>
                              <Select.Option value="sys-monitor">System Monitor</Select.Option>
                              <Select.Option value="supervisor">Supervisor</Select.Option>
                            </Select>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>

                    <div className="col-span-12 mt-5">
                      <label className="block text-sm font-medium leading-5 text-gray-700">
                        Plazas
                      </label>
                      <MultiSelect
                        required
                        options={transformedPlazas}
                        value={formData.plazas}
                        onChange={(plazas) => {
                          setFormData({
                            ...formData,
                            plazas: plazas,
                          });
                        }}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out"
                    >
                      {loading ? 'Loading...' : 'Save'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </SideSheet>
  );
}

export default EditUser;
