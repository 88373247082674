import React, { Fragment, useState, Suspense, useEffect } from 'react';
import { useLocation, Link, Switch, useHistory } from 'react-router-dom';
import routes from '../../routes';
import PrivateRoute from '../privateRoute';
import { Spinner, CornerDialog } from 'evergreen-ui';
import headerImage from './headerImage.png';
import { Typography } from 'antd';

const loading = (props) => {
  return (
    <Fragment>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    </Fragment>
  );
};

const Layout = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [logoutReguest, setLogoutRequest] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    push('/login');
  };

  const role = sessionStorage.getItem('role');
  const mPlazas = JSON.parse(sessionStorage.getItem('plazas'));
  const access = sessionStorage.getItem('access');
  // useEffect(() => {
  //   mPlazas && mPlazas.length > 0 && push('/settlement');
  // }, [mPlazas]);

  return (
    <Fragment>
      <div>
        <div className="bg-gray-800 ">
          <nav x-data="{ open: false }" className="bg-gray-800">
            <div style={{ padding: '0px 240px 0px' }}>
              <div className="border-b border-gray-700">
                <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-8 w-50" src={headerImage} alt="" />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline">
                        {(access === 'admin' ||
                          access === 'sys-monitor' ||
                          access === 'manager') && (
                          <Link
                            to="/"
                            className={`px-3 py-2 rounded-md text-sm font-medium  ${
                              pathname === '/'
                                ? 'text-white bg-gray-900'
                                : 'hover:text-white hover:bg-gray-700 text-gray-300'
                            } focus:outline-none focus:text-white focus:bg-gray-700`}
                          >
                            Overview
                          </Link>
                        )}
                        {(access === 'admin' ||
                          access === 'sys-monitor' ||
                          access === 'manager') && (
                          <Link
                            to="/plaza"
                            className={`px-3 ml-4 py-2 rounded-md text-sm font-medium  ${
                              pathname === '/plaza'
                                ? 'text-white bg-gray-900'
                                : 'hover:text-white hover:bg-gray-700 text-gray-300'
                            } focus:outline-none focus:text-white focus:bg-gray-700`}
                          >
                            Plazas
                          </Link>
                        )}
                        <Link
                          to="/reports"
                          className={`px-3 ml-4 py-2 rounded-md text-sm font-medium  ${
                            pathname === '/reports'
                              ? 'text-white bg-gray-900'
                              : 'hover:text-white hover:bg-gray-700 text-gray-300'
                          } focus:outline-none focus:text-white focus:bg-gray-700`}
                        >
                          Reports
                        </Link>
                        {access === 'admin' && (
                          <Link
                            to="/pricing"
                            className={`px-3 ml-4 py-2 rounded-md text-sm font-medium  ${
                              pathname === '/pricing'
                                ? 'text-white bg-gray-900'
                                : 'hover:text-white hover:bg-gray-700 text-gray-300'
                            } focus:outline-none focus:text-white focus:bg-gray-700`}
                          >
                            Pricing
                          </Link>
                        )}
                        {access === 'supervisor' && (
                          <Link
                            to="/settlement"
                            className={`px-3 ml-4 py-2 rounded-md text-sm font-medium  ${
                              pathname === '/settlement'
                                ? 'text-white bg-gray-900'
                                : 'hover:text-white hover:bg-gray-700 text-gray-300'
                            } focus:outline-none focus:text-white focus:bg-gray-700`}
                          >
                            Cashup Settlement
                          </Link>
                        )}

                        <Link
                          to="/archives"
                          className={`px-3 ml-4 py-2 rounded-md text-sm font-medium  ${
                            pathname === '/archives'
                              ? 'text-white bg-gray-900'
                              : 'hover:text-white hover:bg-gray-700 text-gray-300'
                          } focus:outline-none focus:text-white focus:bg-gray-700`}
                        >
                          Archives
                        </Link>

                        <Link
                          to="/banking"
                          className={`px-3 ml-4 py-2 rounded-md text-sm font-medium  ${
                            pathname === '/banking'
                              ? 'text-white bg-gray-900'
                              : 'hover:text-white hover:bg-gray-700 text-gray-300'
                          } focus:outline-none focus:text-white focus:bg-gray-700`}
                        >
                          Banking
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center justify-content-center md:ml-6">
                      <Typography.Text style={{ color: 'white', marginRight: 10 }}>
                        {sessionStorage.getItem('name')}
                      </Typography.Text>

                      <button
                        onClick={() => setShowDropdown(!showDropdown)}
                        className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                            fill="currentColor"
                          />
                          <path
                            d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>

                      <div className="ml-3 relative">
                        {showDropdown && (
                          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                            <div className="py-1 rounded-md bg-white shadow-xs">
                              {sessionStorage.getItem('role') === 'superadministrator' &&
                              mPlazas.length === 0 ? (
                                <a
                                  onClick={() => {
                                    push('/settings');
                                    setShowDropdown(false);
                                  }}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  Settings
                                </a>
                              ) : null}
                              <a
                                href="#"
                                onClick={() => setLogoutRequest(!logoutReguest)}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Sign out
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white">
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          className="inline-flex"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                        <path
                          className="hidden"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden border-b border-gray-700 md:hidden">
              <div className="px-2 py-3 sm:px-3">
                <a
                  href="#"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Dashboard
                </a>
                <a
                  href="#"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Team
                </a>
                <a
                  href="#"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Projects
                </a>
                <a
                  href="#"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Calendar
                </a>
                <a
                  href="#"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Reports
                </a>
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">Tom Cook</div>
                    <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                      tom@example.com
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-2">
                  <a
                    href="#"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Your Profile
                  </a>
                  <a
                    href="#"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Settings
                  </a>
                  <a
                    href="#"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <main className="">
          <Suspense fallback={loading()}>
            <Switch>
              {routes.map((screen, i) => (
                <PrivateRoute
                  name={screen.name}
                  key={i}
                  component={screen.component}
                  exact={screen.exact}
                  path={screen.path}
                />
              ))}
            </Switch>
          </Suspense>
        </main>
      </div>
      <CornerDialog
        title="Logout Request"
        isShown={logoutReguest}
        onCloseComplete={() => setLogoutRequest(!setLogoutRequest)}
        onConfirm={handleLogout}
        confirmLabel={'Yes'}
      >
        Are you sure you want to logout?
      </CornerDialog>
    </Fragment>
  );
};

export default Layout;
