import React, { Fragment, useState, useEffect } from 'react';
import { toaster, Spinner } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import useAxios from 'axios-hooks';
import constants from '../../../constants';
import config from '../../../config';

const Layout = (props) => {
  let { push } = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [{ loading }, loginUser] = useAxios(
    {
      url: `${config}/api/v1/administrator`,
      method: 'post',
      headers: { token: constants.token },
      data: {
        email,
        password,
      },
    },
    { manual: true },
  );

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      push('/');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser();
      // console.log(response);
      sessionStorage.setItem('login?', true);
      sessionStorage.setItem('admintoken', response.data.token);
      sessionStorage.setItem('role', response.data.user.role);
      sessionStorage.setItem('access', response.data.user.access);
      sessionStorage.setItem('plazas', JSON.stringify(response.data.user.plazas));
      sessionStorage.setItem('name', response.data.user.name);
      push('/');
    } catch (e) {
      toaster.danger(e.response.data);
    }
  };
  return (
    <Fragment>
      <div
        style={{
          background:
            'linear-gradient(0deg, rgba(237, 108, 9, 0.6), rgba(0, 0, 0, 0.8)), url(https://images.unsplash.com/photo-1611839267623-8a861c18d52c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80)',
        }}
        className="min-h-screen bg-green-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src={require('../../../components/layout/headerImage.png')}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
            HDG Homes
          </h2>
          <h5 className="mt-6 text-center leading-9 font-extrabold text-white">Toll System</h5>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white  py-20 px-15 shadow rounded-md  sm:px-10">
            <form onSubmit={handleLogin}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1  shadow-sm">
                  <input
                    type="email"
                    required
                    placeholder="Enter email here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300  placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 shadow-sm">
                  <input
                    id="password"
                    type="password"
                    placeholder="Enter your password here"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300  placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-sm leading-5 text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    {loading ? <Spinner size={20} /> : 'Sign In'}
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
