import React, { useRef, useState } from 'react';
import { SideSheet, toaster } from 'evergreen-ui';
import useAxios from 'axios-hooks';

import ReactToPrint from 'react-to-print';
import {
  Button,
  DatePicker,
  Drawer,
  Typography,
  Form,
  Input,
  Descriptions,
  notification,
  InputNumber,
  Row,
  Col,
  Radio,
} from 'antd';
import config from '../../config';
import constants from '../../constants';
import moment from 'moment';

class ToBePrinted extends React.Component {
  render() {
    const { session } = this.props;
    let total = 0;
    let totalAmount = 0;
    let cash = 0;
    let loyalty = 0;
    if (typeof session.cash === 'number') {
      // console.log(typeof session.cash);
      cash = cash + session?.cash;
      loyalty = loyalty + session?.loyalty;
    }

    if (session) {
      const {
        salon,
        pickup,
        minitrk,
        trkbus,
        hvyduty,
        exempt,
        motor,
        tricycle,
      } = session.vehiclesCount;
      total = salon + pickup + minitrk + trkbus + hvyduty + exempt + motor + tricycle;
      totalAmount =
        salon * 0.5 +
        motor * 0.1 +
        tricycle * 0.5 +
        pickup * 1 +
        minitrk * 1.5 +
        trkbus * 2 +
        hvyduty * 2.5;
    }

    return (
      <div style={{}}>
        <center>
          <h2 style={{ color: '#190840' }}>Shift Details</h2>
          <p style={{ color: '#190840' }}>{session.plaza.name}</p>
        </center>

        <div className="mt-5 border-t border-gray-200 pt-5">
          <dl>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Start Time
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {new Date(session.startTime).toLocaleDateString()} at{' '}
                  {new Date(session.startTime).toLocaleTimeString()}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Total Amount
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc {(totalAmount + session?.manual).toFixed(2)}
                </dd>
              </div>
              <div style={{ flex: 1, textAlign: 'center', color: '#190840' }}>
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  End Time
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {new Date(session.endTime).toLocaleDateString()} at{' '}
                  {new Date(session.endTime).toLocaleTimeString()}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ color: '#190840', flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Shift ID
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.shiftID}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Booth
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.booth}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ color: '#190840', flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  POS ID
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.posID}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Cash
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  Ghc {cash.toFixed(2)}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Manual
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc {session?.manual}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Loyalty
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc {loyalty.toFixed(2)}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  User Full Name
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.user.fullName}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Arrears
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc{' '}
                  {session?.amountSettled > 0
                    ? (session?.cash + session?.manual - session?.amountSettled).toFixed(2)
                    : '-'}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  User Telephone
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.user.phone}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-2 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Uploaded At
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {new Date(session.updatedAt).toDateString()} at{' '}
                  {new Date(session.updatedAt).toLocaleTimeString()}
                </dd>
              </div>
            </div>
          </dl>

          <div
            style={{
              marginTop: '30px',
              marginLeft: '40px',
              marginRight: '40px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="flex"
          >
            <table className="flex-1 table-auto">
              <thead>
                <tr>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Vehicle Type
                  </th>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Quantity
                  </th>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Charge
                  </th>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Total
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Motor
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.motor}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0.10
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.motor * 0.1).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Tricycle
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.tricycle}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.tricycle * 0.5).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Salon
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.salon}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.salon * 0.5).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Pickup
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.pickup}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    1.00
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.pickup * 1).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Minitruck
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.minitrk}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    1.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.minitrk * 1.5).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Truck / Bus
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.trkbus}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    2.00
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.trkbus * 2).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Heavy Duty
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.hvyduty}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    2.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.hvyduty * 2.5).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Exempt
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.exempt}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.hvyduty * 0).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2"></td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {total}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2"></td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {totalAmount.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <Descriptions
              bordered
              size="small"
              layout="vertical"
              column={1}
              style={{ marginTop: '10px', marginLeft: '20px' }}
              title="Car Counts"
            >
              <Descriptions.Item label="System Counts(a)">
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item style={{ color: '#190840' }} label="b">
                    {session.loopCounts.a}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ color: '#190840' }} label="s">
                    {session.loopCounts.b}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ color: '#190840' }} label="Average">
                    {Math.round((session.loopCounts.a + session.loopCounts.b) / 2)}
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>
              <Descriptions.Item style={{ color: '#190840' }} label="Collector's Count(b)">
                {total}
              </Descriptions.Item>
              <Descriptions.Item style={{ color: '#190840' }} label="Descrepency(a-b)">
                {Math.round((session.loopCounts.a + session.loopCounts.b) / 2) - total}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    );
  }
}

function ViewCashup({ onCloseComplete, isShown, session, getSessions }) {
  let total = 0;
  let totalAmount = 0;
  let cash = 0;
  let loyalty = 0;

  const [viewForm, setViewForm] = useState(false);

  const [{ loading }, createSettlement] = useAxios(
    {
      url: `${config}/api/v1/settle`,
      method: 'post',
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },
    { manual: true },
  );

  if (session) {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;
    if (typeof session.cash === 'number') {
      // console.log(typeof session.cash);
      cash = cash + session?.cash + session?.manual;
      loyalty = loyalty + session?.loyalty;
    }

    total = salon + pickup + minitrk + trkbus + hvyduty + exempt + motor + tricycle;
    totalAmount =
      salon * 0.5 +
      motor * 0.1 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5;
  }
  // console.log(session.vehiclesCount);
  const componentRef = useRef();

  const [hasManual, setHasManual] = useState(false);
  const options = [
    { label: 'No', value: false },
    { label: 'Yes ', value: true },
    // { label: 'Orange', value: 'Orange' },
  ];

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      {!session ? null : (
        <div style={{ padding: '30px' }}>
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h3>Shift Details</h3>
          </div>
          <div style={{ display: 'none' }}>
            <ToBePrinted ref={componentRef} session={session} />
          </div>
          <Descriptions layout="vertical" bordered size={'small'}>
            <Descriptions.Item label="Plaza">
              <Typography.Text>{session?.plaza?.name}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Booth">
              <Typography.Text>{session?.booth}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="POS ID">
              <Typography.Text>{session?.posID}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Shift ID">
              <Typography.Text>{session?.shiftID}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Cashier">
              <Typography.Text>{session?.user?.fullName}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Cash Amount">
              <Typography.Text>{session?.amountSettled > 0 ? session?.cash : '-'}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Manual">
              <Typography.Text>
                &cent; {session?.amountSettled > 0 ? session?.manual : '-'}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount">
              <Typography.Text>
                &cent; {session?.amountSettled > 0 ? session?.manual + session?.cash : '-'}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Amount Settled">
              <Typography.Text>
                &cent; {session?.amountSettled > 0 ? session?.amountSettled : '-'}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Arrears">
              <Typography.Text>
                {session?.amountSettled > 0
                  ? (session?.cash + session?.manual - session?.amountSettled).toFixed(2)
                  : '-'}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Last Settled">
              <Typography.Text>
                {session?.lastSettled
                  ? moment(session?.lastSettled).format('MMM, Do YYYY')
                  : 'No History'}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          {viewForm ? (
            <div style={{ marginTop: 20 }}>
              <Typography.Title>Settle Cashup</Typography.Title>
              <Form
                onFinish={(t) => {
                  // console.log(t);

                  if (t?.amount !== t?.amountRepeat) {
                    notification.error({
                      message: 'Settlement Amount and Repeat Amount does not match',
                      placement: 'bottomRight',
                    });
                    return;
                  }

                  const payload = {
                    shift: session?._id,
                    plaza: session?.plaza?._id,
                    manual: Number(t?.manual),
                    amount: Number(t?.amount),
                  };

                  // alert("hi")

                  createSettlement({ data: { ...payload } })
                    .then(() => {
                      toaster.success('Request Sucessfully', {
                        description: 'Settlement has been recorded successfully',
                      });
                      getSessions();
                      setViewForm(false);
                      setHasManual(false);
                      onCloseComplete(false);
                    })
                    .catch((e) => {
                      toaster.danger('Something went wrong', {
                        description: e.message,
                      });
                    });
                }}
                layout="vertical"
              >
                <Form.Item label="Did Cashier print Manual tickets?">
                  <Radio.Group
                    options={options}
                    onChange={(t) => {
                      setHasManual(t.target.value);
                    }}
                    value={hasManual}
                    optionType="button"
                  />
                </Form.Item>
                {hasManual && (
                  <Form.Item
                    hasFeedback
                    name="manual"
                    initialValue={0}
                    label="Manual Ticket Amount"
                  >
                    <Input defaultValue={0} inputMode="decimal" placeholder="Manual Amount" />
                  </Form.Item>
                )}
                <Form.Item
                  hasFeedback
                  required={true}
                  name="amount"
                  rules={[{ required: true, min: 0 }]}
                  label="Amount to Settle"
                >
                  <Input required inputMode="decimal" placeholder="Amount" />
                </Form.Item>

                <Form.Item
                  hasFeedback
                  required={true}
                  name="amountRepeat"
                  rules={[{ required: true, min: 0 }]}
                  label="Repeat Amount"
                >
                  <Input required inputMode="decimal" placeholder="Repeat Settlement Amount" />
                </Form.Item>

                <Row>
                  <Col>
                    <Form.Item style={{ marginTop: 10 }}>
                      <Button loading={loading} htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        setViewForm(false);
                        setHasManual(false);
                      }}
                      // type=""
                      style={{ color: 'red', marginTop: 10, marginLeft: 10 }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : session?.amountSettled == 0 ? (
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    setViewForm(true);
                  }}
                  style={{ marginTop: 10 }}
                >
                  Add Settlement
                </Button>
              </Col>
            </Row>
          ) : (
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  onClick={() => {
                    // setShowAddPlaza(true);
                    // alert("hi")
                  }}
                  class="inline-flex items-center mt-5 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {' '}
                  Print Cash up report
                </button>
              )}
              content={() => componentRef.current}
            />
          )}
        </div>
      )}
    </SideSheet>
  );
}

export default ViewCashup;
