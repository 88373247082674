import React, { Fragment } from 'react';
import Header from '../../components/layout/header';
import useAxios from 'axios-hooks';
import constants from '../../constants';
import { Link } from 'react-router-dom';
import Loading from '../../components/loadingState';
import AddUser from './components/AddUser';
import EditUser from './components/EditUser';
import config from '../../config';

const Settings = () => {
  const [addUser, setAddUser] = React.useState({
    isShowing: false,
  });
  const [editUser, setEditUser] = React.useState({
    isShowing: false,
    user: {},
  });

  const [{ data, loading, error }] = useAxios({
    url: `${config}/api/v1/administrator`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const [{ data: plazaData, loading: plazaLoading, error: plazaError }] = useAxios({
    url: `${config}/api/v1/plaza`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  if (loading || plazaLoading) {
    return <Loading />;
  }

  if (error) {
    console.error(error);
  }

  return (
    <Fragment>
      <div>
        <Header title="Settings" />

        <div style={{ padding: '30px 240px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h3>Manage Users</h3>

            <a
              className="text-indigo-600 hover:text-indigo-900"
              onClick={() => {
                setAddUser({
                  isShowing: true,
                });
              }}
            >
              Create User
            </a>
          </div>
          <div className="flex flex-col mt-5">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Access
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Created At
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.map((user) => {
                      return (
                        <>
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {user.name}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {user.email}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                              {user?.access}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                              {new Date(user.createdAt).toDateString()}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                              <a
                                className="text-indigo-600 hover:text-indigo-900"
                                onClick={() => {
                                  setEditUser({
                                    isShowing: true,
                                    user,
                                  });
                                }}
                              >
                                Edit
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddUser
        plazas={plazaData}
        isShown={addUser.isShowing}
        onCloseComplete={() => {
          setAddUser({
            isShowing: false,
          });
        }}
      />

      <EditUser
        plazas={plazaData}
        isShown={editUser.isShowing}
        onCloseComplete={() => {
          window.location.reload();
          setEditUser({
            isShowing: false,
            user: {},
          });
        }}
        user={editUser.user}
      />
    </Fragment>
  );
};

export default Settings;
