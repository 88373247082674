export default [
  'Northern',
  'Ashanti',
  'Western',
  'Volta',
  'Eastern',
  'Upper West',
  'Central',
  'Upper East',
  'Greater Accra',
  'Savannah',
  'North East',
  'Oti',
  'Ahafo',
  'Bono',
  'Western North',
];
