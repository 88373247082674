import React, { Fragment, useEffect } from 'react';
import Header from '../../components/layout/header';
import useAxios from 'axios-hooks';
import constants from '../../constants';
import Loading from '../../components/loadingState';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AddPlazaUser from './components/addPlazaUser';
import moment from 'moment';
import ViewSession from './components/viewSession';
import EditPlazaUser from './components/editPlazaUser';
import { Row, DatePicker, Col } from 'antd';
import config from '../../config';

const { RangePicker } = DatePicker;

const ViewPlaza = (props) => {
  const currentDate = new Date();

  currentDate.setDate(currentDate.getDate() - 2);
  // console.log("Date", currentDate.toLocaleDateString())

  let { plaza } = useParams();
  const [openAddPlazaUser, setopenAddPlazaUser] = React.useState(false);
  const [openEditPlazaUser, setopenEditPlazaUser] = React.useState(false);
  const [openViewSession, setOpenViewSession] = React.useState(false);
  const [session, selectSession] = React.useState('');
  const [user, selectUser] = React.useState('');
  const [dt, setDt] = React.useState([currentDate.toDateString(), new Date().toDateString()]);

  const [{ data, loading, error }, allUsers] = useAxios(
    {
      url: `${config}/api/v1/users/${plaza}`,
      // url: `http://localhost:3000/api/v1/users/${plaza}`,
      headers: { token: constants.token },
    },
    { manual: true },
  );

  // console.log(moment(dt[0]).format( 'MMMM YYYY-DD-MM'));

  useEffect(() => {
    allUsers();
  }, [openEditPlazaUser]);

  const [{ data: ssData, loading: sessionLoading, error: sessionError }] = useAxios({
    url: `${config}/api/v1/session/${plaza}?startDate=${moment(dt[0]).format(
      'YYYY-MM-DD',
    )}&endDate=${moment(dt[1]).format('YYYY-MM-DD')}`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const [
    { data: sessionData2, loading: sessionLoading2, error: sessionError2 },
    getDataByDate,
  ] = useAxios({
    url: `${config}/api/v1/session/${plaza}?startDate=${moment(dt[0]).format(
      'YYYY-MM-DD',
    )}&endDate=${moment(dt[1]).format('YYYY-MM-DD')}`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const sessionData = dt.length > 1 ? sessionData2 : ssData;

  if (loading || sessionLoading) {
    return <Loading />;
  }

  let totalAmountMade = 0;
  let total = 0;
  sessionData &&
    sessionData.map((session) => {
      const {
        salon,
        pickup,
        minitrk,
        trkbus,
        hvyduty,
        exempt,
        motor,
        tricycle,
      } = session.vehiclesCount;
      total =
        salon * 5 +
        motor * 0.1 +
        tricycle * 0.5 +
        pickup * 1 +
        minitrk * 1.5 +
        trkbus * 2 +
        hvyduty * 2.5;
      totalAmountMade += total;
    });

  const getShiftTotalAmount = (session) => {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;
    return (
      salon * 5 +
      motor * 0.5 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5
    );
  };

  return (
    <Fragment>
      <Header title={`${plaza} Plaza`} />

      <div style={{ padding: '30px 240px' }}>
        <div style={{ marginBottom: '50px' }}>
          <Row>
            <Col md={18} lg={18}>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {moment(dt[0]).format('dddd, Do MMMM')} - {moment(dt[1]).format('dddd, Do MMMM')}
              </h3>
            </Col>

            <RangePicker
              onChange={(values, dt) => {
                setDt(dt);
                getDataByDate();
              }}
            />
          </Row>

          <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
            <div>
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-base leading-6 font-normal text-gray-900">Total Users</dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                      {data.length}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="border-t border-gray-200 md:border-0 md:border-l">
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-base leading-6 font-normal text-gray-900">
                    Total Number of Shifts
                  </dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                      {sessionData?.length}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="border-t border-gray-200 md:border-0 md:border-l">
              <div className="px-4 py-5 sm:p-6">
                <dl>
                  <dt className="text-base leading-6 font-normal text-gray-900">
                    Total Amount Made
                  </dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                      GHc {totalAmountMade.toFixed(2)}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Shifts</Tab>
          </TabList>

          <TabPanel>
            <div>
              <div
                style={{
                  marginBottom: '30px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <h3>Users</h3>

                <a
                  className="text-indigo-600 hover:text-indigo-900"
                  onClick={() => setopenAddPlazaUser(true)}
                >
                  Create User
                </a>
              </div>
              <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Full Name
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Username
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Phone Number
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Role
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Active
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Registration Date
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {data.map((user) => {
                          return (
                            <>
                              <tr>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {user.fullName}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {user.name}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {user.phone}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {user.role}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {user.disabled ? 'No' : 'Yes'}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                                  {new Date(user.createdAt).toDateString()}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                  <a
                                    onClick={() => {
                                      selectUser(user);
                                      setopenEditPlazaUser(true);
                                    }}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <div className="flex flex-col">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Shift ID
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            POS ID
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            POS Rev.
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Manual Rev.
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Total Rev
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            User
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Uploaded At
                          </th>
                          <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {sessionData?.map((session) => {
                          return (
                            <>
                              <tr>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {session.shiftID}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {session.posID}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  &cent; {getShiftTotalAmount(session)}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  &cent; {session?.manual || 0}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  GHc{' '}
                                  {session?.manual
                                    ? (getShiftTotalAmount(session) + session?.manual).toFixed(2)
                                    : getShiftTotalAmount(session).toFixed(2)}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                  {session.user.fullName}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                                  {new Date(session.createdAt).toDateString()}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                  <a
                                    onClick={() => {
                                      selectSession(session);
                                      setOpenViewSession(true);
                                    }}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    View
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>

      <AddPlazaUser
        isShown={openAddPlazaUser}
        onCloseComplete={() => setopenAddPlazaUser(false)}
        plaza={plaza}
      />

      {openEditPlazaUser ? (
        <EditPlazaUser
          isShown={openEditPlazaUser}
          onCloseComplete={() => setopenEditPlazaUser(false)}
          user={user}
        />
      ) : null}

      {openViewSession ? (
        <ViewSession
          isShown={openViewSession}
          onCloseComplete={() => setOpenViewSession(false)}
          session={session}
        />
      ) : null}
    </Fragment>
  );
};

export default ViewPlaza;
