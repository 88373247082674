import React, { Fragment, useEffect } from 'react';
import Header from '../../components/layout/header';
import useAxios from 'axios-hooks';
import { useHistory } from 'react-router-dom';
import constants from '../../constants';
import Loading from '../../components/loadingState';
import ViewSession from '../plaza/components/viewSession';
import config from '../../config';

const Overview = (props) => {
  const [{ data: ssData, loading: sessionLoading, error: sessionError }] = useAxios({
    url: `${config}/api/v1/session`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });
  const [openViewSession, setOpenViewSession] = React.useState(false);
  const [session, selectSession] = React.useState('');
  const [plazas, setPlazas] = React.useState([]);

  const { push } = useHistory();

  async function getPlazas() {
    const nP = JSON.parse(sessionStorage.getItem('plazas'));
    let npList = [];
    nP.map((item) => {
      npList.push(item?.name);
    });
    setPlazas(npList);
  }

  const role = sessionStorage.getItem('role');
  const mPlazas = JSON.parse(sessionStorage.getItem('plazas'));
  const access = sessionStorage.getItem('access');

  useEffect(() => {
    getPlazas();
  }, []);

  useEffect(() => {
    access === 'supervisor' && push('/settlement');
  }, [mPlazas]);

  if (sessionLoading) {
    return <Loading />;
  }

  let sessionData =
    plazas.length > 0 ? ssData.filter((item) => plazas.includes(item?.plaza?.name)) : ssData;

  let totalAmountMade = 0;
  let total = 0;
  let cash = 0;
  let loyalty = 0;
  sessionData.map((session) => {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;

    if (typeof session.cash === 'number') {
      // console.log(typeof session.cash);
      cash = cash + session?.cash;
      loyalty = loyalty + session?.loyalty;
    }

    total =
      salon * 5 +
      motor * 0.5 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5;
    totalAmountMade += total;
  });

  const getShiftTotalAmount = (session) => {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;
    return (
      salon * 5 +
      motor * 0.5 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5
    );
  };

  // console.log(sessionData);

  return (
    <Fragment>
      <Header title="Overview" />

      <div style={{ padding: '30px 240px' }}>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Today</h3>
        <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3">
          <li key={1} className="relative col-span-1 flex shadow-sm rounded-md">
            <div
              className={
                'bg-green-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              }
            >
              Shifts
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">
                  No. Shifts
                </a>
                <p className="text-gray-500"> {sessionData.length}</p>
              </div>
            </div>
          </li>
          <li key={1} className="relative col-span-1 flex shadow-sm rounded-md">
            <div
              className={
                'bg-indigo-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              }
            >
              GH&cent;
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">
                  Total Revenue
                </a>
                <p className="text-gray-500"> &cent; {totalAmountMade.toFixed(2)}</p>
              </div>
            </div>
          </li>
          <li key={1} className="relative col-span-1 flex shadow-sm rounded-md">
            <div
              className={
                'bg-yellow-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              }
            >
              Cash
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">
                  Cash
                </a>
                <p className="text-gray-500">&cent;{(totalAmountMade - loyalty).toFixed(2)}</p>
              </div>
            </div>
          </li>
          <li key={1} className="relative col-span-1 flex shadow-sm rounded-md">
            <div
              className={
                'bg-blue-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              }
            >
              Loyalty
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">
                  Loyalty
                </a>
                <p className="text-gray-500">
                  &cent;{(totalAmountMade - (totalAmountMade - loyalty)).toFixed(2)}
                </p>
              </div>
            </div>
          </li>
        </ul>

        <div style={{ marginTop: '30px' }}>
          <div className="flex flex-col">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Plaza
                      </th>
                      <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Shift ID
                      </th>
                      <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        POS ID
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Booth
                      </th>
                      <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Cash
                      </th>
                      <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Loyalty
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        User
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Shift Date
                      </th>
                      <th className="px-2 py-3 border-b border-gray-200 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {sessionData.map((session) => {
                      return (
                        <>
                          <tr>
                            <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.user.plaza.name}
                            </td>
                            <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.shiftID}
                            </td>
                            <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.posID}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.booth}
                            </td>
                            <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              GHc {getShiftTotalAmount(session).toFixed(2)}
                            </td>
                            <td className="px-2 py-4 whitespace-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session?.cash?.toFixed(2)}
                            </td>
                            <td className="px-2 py-4 whitespace-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session?.loyalty?.toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.user.fullName}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                              {new Date(session.startTime).toDateString()}
                            </td>
                            <td className="px-2 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                              <a
                                onClick={() => {
                                  selectSession(session);
                                  setOpenViewSession(true);
                                }}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ViewSession
        isShown={openViewSession}
        onCloseComplete={() => setOpenViewSession(false)}
        session={session}
      />
    </Fragment>
  );
};

export default Overview;
