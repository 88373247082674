import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Header from '../../components/layout/header';
import config from '../../config';
import constants from '../../constants';
import useAxios from 'axios-hooks';
import axios from 'axios';
import Loading from '../../components/loadingState';
import {
  Button,
  DatePicker,
  Descriptions,
  Drawer,
  Typography,
  Form,
  Input,
  Dialog,
  InputNumber,
  Popconfirm,
  notification,
} from 'antd';
import moment from 'moment';
import { toaster } from 'evergreen-ui';
import ViewCashup from './viewCashupReport';

export default function Settlement() {
  const [{ data: ssData, loading: sessionLoading, error: sessionError }, getSession] = useAxios({
    url: `${config}/api/v1/session`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const [{ loading }, createSettlement] = useAxios(
    {
      url: `${config}/api/v1/settle`,
      method: 'post',
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },
    { manual: true },
  );

  const [session, selectSession] = React.useState('');
  const [plazas, setPlazas] = React.useState([]);
  const [showSettlementDrawer, setShowSettlementDrawer] = useState(false);
  const [ds, setDS] = useState(new Date().toDateString());

  const [viewForm, setViewForm] = useState(false);

  const [{ loading: loadingEdit, error }, generateReportAll] = useAxios(
    {
      url: `${config}/api/v1/session?startDate=${ds}&endDate=${ds}`,
      headers: {
        token: constants.token,
        admintoken: sessionStorage.getItem('admintoken'),
      },
    },
    {
      manual: true,
    },
  );

  async function getPlazas() {
    const nP = JSON.parse(sessionStorage.getItem('plazas'));
    let npList = [];
    nP.map((item) => {
      npList.push(item?.name);
    });

    setPlazas(npList);
  }

  useEffect(() => {
    getPlazas();
  }, []);

  let [sessionData, setSessionDate] = useState([]);

  useEffect(() => {
    setSessionDate(
      plazas.length > 0 ? ssData.filter((item) => plazas.includes(item?.plaza?.name)) : ssData,
    );
  }, [ssData]);

  async function getSessions() {
    try {
      const n = await axios.get(`${config}/api/v1/session?startDate=${ds}&endDate=${ds}`, {
        headers: {
          token: constants.token,
          admintoken: sessionStorage.getItem('admintoken'),
        },
      });
      // console.log(n.data);
      setSessionDate(
        plazas.length > 0 ? n.data.filter((item) => plazas.includes(item?.plaza?.name)) : n.data,
      );
    } catch (e) {
      toaster.danger('Error', { description: e.message });
    }
  }

  if (sessionLoading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Header
        title="Cashup Settlements"
        additional={() => (
          <DatePicker
            onChange={async (t, ds) => {
              setDS(ds);
              try {
                const n = await axios.get(
                  `${config}/api/v1/session?startDate=${ds}&endDate=${ds}`,
                  {
                    headers: {
                      token: constants.token,
                      admintoken: sessionStorage.getItem('admintoken'),
                    },
                  },
                );
                // console.log(n.data);
                setSessionDate(
                  plazas.length > 0
                    ? n.data.filter((item) => plazas.includes(item?.plaza?.name))
                    : n.data,
                );
              } catch (e) {
                toaster.danger('Error', { description: e.message });
              }
            }}
          />
        )}
      />
      <div style={{ padding: '30px 240px' }}>
        <div className="flex flex-col">
          <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-2 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Plaza
                    </th>
                    <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Shift ID
                    </th>
                    {/* <th className="px-2 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      POS ID
                    </th> */}
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Booth
                    </th>

                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      User
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Amount Settled
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Shift Date
                    </th>
                    <th className="px-2 py-3 border-b border-gray-200 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {sessionData?.map((session) => {
                    return (
                      <>
                        <tr>
                          <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {session.user.plaza.name}
                          </td>
                          <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {session.shiftID}
                          </td>
                          {/* <td className="px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {session.posID}
                          </td> */}
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {session.booth}
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {session.user.fullName}
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {session?.amountSettled?.toFixed(2)}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-${
                              session?.amountSettled === 0
                                ? 'grey'
                                : session?.amountSettled > 0 &&
                                  session?.cash > session?.amountSettled
                                ? 'red'
                                : session?.amountSettled > 0 &&
                                  session?.cash < session?.amountSettled
                                ? 'yellow'
                                : 'green'
                            }-500`}
                          >
                            {session?.amountSettled === 0
                              ? 'Not Settled'
                              : session?.amountSettled > 0 && session?.cash > session?.amountSettled
                              ? 'Shortage'
                              : session?.amountSettled > 0 && session?.cash < session?.amountSettled
                              ? 'Overage'
                              : 'Settled'}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            {new Date(session.startTime).toDateString()}
                          </td>
                          <td className="px-2 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                            <a
                              onClick={() => {
                                selectSession(session);
                                console.log(session);
                                setShowSettlementDrawer(true);
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ViewCashup
        isShown={showSettlementDrawer}
        onCloseComplete={() => setShowSettlementDrawer(false)}
        session={session}
        getSessions={getSessions}
      />
      <Drawer
        // visible={showSettlementDrawer}
        width={600}
        destroyOnClose
        title={<Typography.Title>Settle Transaction</Typography.Title>}
        onClose={(t) => setShowSettlementDrawer(false)}
      >
        <Descriptions layout="vertical" bordered size={'small'}>
          <Descriptions.Item label="Plaza">
            <Typography.Text>{session?.plaza?.name}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Booth">
            <Typography.Text>{session?.booth}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="POS ID">
            <Typography.Text>{session?.posID}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Shift ID">
            <Typography.Text>{session?.shiftID}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Cashier">
            <Typography.Text>{session?.user?.fullName}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Cash Amount">
            <Typography.Text>{session?.amountSettled > 0 ? session?.cash : '-'}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Manual">
            <Typography.Text>
              &cent; {session?.amountSettled > 0 ? session?.manual : '-'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Arrears">
            <Typography.Text>
              {session?.amountSettled > 0
                ? (session?.cash + session?.manual - session?.amountSettled).toFixed(2)
                : '-'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Last Settled">
            <Typography.Text>
              {session?.lastSettled
                ? moment(session?.lastSettled).format('MMM, Do YYYY')
                : 'No History'}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
        {viewForm ? (
          <div style={{ marginTop: 20 }}>
            <Typography.Title>Settle Cashup mmmm</Typography.Title>
            <Form
              onFinish={(t) => {
                // console.log(t);

                if (t?.amount !== t?.amountRepeat) {
                  notification.error({
                    message: 'Settlement Amount and Repeat Amount does not match',
                    placement: 'bottomRight',
                  });
                  return;
                }

                const payload = {
                  shift: session?._id,
                  plaza: session?.plaza?._id,
                  manual: Number(t?.manual),
                  amount: Number(t?.amount),
                };
                alert("hi")

                // createSettlement({ data: { ...payload } })
                //   .then(() => {
                //     toaster.success('Request Sucessfully', {
                //       description: 'Settlement has been recorded successfully',
                //     });
                //     getSessions();
                //     setViewForm(false);
                //     setShowSettlementDrawer(false);
                //   })
                //   .catch((e) => {
                //     toaster.danger('Something went wrong', {
                //       description: e.message,
                //     });
                //   });
              }}
              layout="vertical"
            >
              <Form.Item hasFeedback name="manual" initialValue={0} label="Manual Ticket Amount">
                <Input defaultValue={0} inputMode="decimal" placeholder="Manual Amount" />
              </Form.Item>

              <Form.Item
                hasFeedback
                required={true}
                name="amount"
                rules={[{ required: true, min: 0 }]}
                label="Amount to Settle"
              >
                <Input required inputMode="decimal" placeholder="Amount" />
              </Form.Item>
              <Form.Item
                hasFeedback
                required={true}
                name="amountRepeat"
                rules={[{ required: true, min: 0 }]}
                label="Repeat Amount"
              >
                <Input required inputMode="decimal" placeholder="Repeat Settlement Amount" />
              </Form.Item>

              <Form.Item style={{ marginTop: 10 }}>
                <Button loading={loading} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : session?.amountSettled == 0 ? (
          <Button
            onClick={() => {
              setViewForm(true);
            }}
            style={{ marginTop: 10 }}
          >
            Add Settlement
          </Button>
        ) : (
          <button
            type="button"
            onClick={() => {
              // setShowAddPlaza(true);
              // alert("hi")
            }}
            class="inline-flex items-center mt-5 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {' '}
            Print Cash up report
          </button>
        )}
      </Drawer>
    </Fragment>
  );
}
