import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../components/layout/header';
import useAxios from 'axios-hooks';
import constants from '../../constants';
import Loading from '../../components/loadingState';
import Empty from '../../components/EmptyState';
import { Link } from 'react-router-dom';
import config from '../../config';
import { Button, Form, Drawer, Input, Select } from 'antd';
import { Dialog, toaster } from 'evergreen-ui';
import regions from '../overview/regions';

const { Option } = Select;

const Plaza = (props) => {
  const [{ data: ssPlaza, loading, error }, loadPlazas] = useAxios({
    url: `${config}/api/v1/plaza`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });
  const [plazas, setPlazas] = React.useState([]);
  const [region, setRegion] = useState(null);
  const [showAddPlaza, setShowAddPlaza] = useState(false);
  const role = sessionStorage.getItem('role');

  async function getPlazas() {
    const nP = JSON.parse(sessionStorage.getItem('plazas'));
    let npList = [];
    nP.map((item) => {
      npList.push(item?.name);
    });
    setPlazas(npList);
    loadPlazas();
  }

  useEffect(() => {
    getPlazas();
  }, []);

  const [{ loading: creatingPlaza }, createPlaza] = useAxios(
    {
      url: `${config}/api/v1/plaza`,
      method: 'post',
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },
    { manual: true },
  );

  // let data = plazas.length > 0 ? ssPlaza.filter((item) => plazas.includes(item?.name)) : data;

  if (loading) {
    return <Loading />;
  }
  let data = plazas.length > 0 ? ssPlaza.filter((item) => plazas.includes(item?.name)) : ssPlaza;

  if (data.length === 0) {
    return (
      <Fragment>
        <Header
          title="Plazas"
          additional={() =>
            role === 'superadministrator' &&
            plazas.length === 0 && (
              <button
                type="button"
                onClick={() => {
                  setShowAddPlaza(true);
                  // alert("hi")
                }}
                class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {' '}
                Add Plaza
              </button>
            )
          }
        />
        <Empty title="No Plazas" subTitle="You have no plazas in system" />
        <Dialog
          isShown={showAddPlaza}
          title="Add Plaza"
          intent="none"
          hasFooter={false}
          preventBodyScrolling
          onCloseComplete={() => setShowAddPlaza(false)}
          confirmLabel="Delete"
        >
          <Form
            onFinish={(t) => {
              if (!t?.region) {
                toaster.warning('Region is required to create plaza, please select region');
                return;
              }
              createPlaza({
                data: {
                  ...t,
                },
              })
                .then(() => {
                  setShowAddPlaza(false);
                  getPlazas();
                  toaster.success('Request is successful', {
                    description: 'Plaza created successfully',
                  });
                })
                .catch((error) => {
                  toaster.danger('Request failed', {
                    description:
                      error.response && error.response.data
                        ? error.response.data.message
                        : 'Unknown error',
                  });
                });
              console.log(t);
            }}
            layout="vertical"
          >
            <Form.Item name="name" hasFeedback label="Plaza name" required>
              <Input required placeholder="Enter plaza name" />
            </Form.Item>
            <Form.Item name="region" label="Region" required>
              <Select onChange={(t) => setRegion(t)} placeholder="Select Region" bordered>
                {regions.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <button
                type="submit"
                disabled={creatingPlaza}
                onClick={() => {
                  // setShowAddPlaza(true);
                  // alert("hi")
                }}
                class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {' '}
                {creatingPlaza ? 'Loading' : 'Submit'}
              </button>
            </Form.Item>
          </Form>
        </Dialog>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header
        title="Plaza"
        additional={() =>
          role === 'superadministrator' &&
          plazas.length === 0 && (
            <button
              type="button"
              onClick={() => {
                setShowAddPlaza(true);
                // alert("hi")
              }}
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {' '}
              Add Plaza
            </button>
          )
        }
      />
      <div style={{ padding: '30px 240px' }}>
        <div className="flex flex-col">
          <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Created At
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data?.map((plaza) => {
                    return (
                      <>
                        <tr>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {plaza.name}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            {new Date(plaza.createdAt).toDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                            <Link to={`/plaza/${plaza.name}`}>
                              <a className="text-indigo-600 hover:text-indigo-900">View</a>
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        isShown={showAddPlaza}
        title="Add Plaza"
        intent="none"
        hasFooter={false}
        preventBodyScrolling
        onCloseComplete={() => setShowAddPlaza(false)}
        confirmLabel="Delete"
      >
        <Form
          onFinish={(t) => {
            if (!t?.region) {
              toaster.warning('Region is required to create plaza, please select region');
              return;
            }
            createPlaza({
              data: {
                ...t,
              },
            })
              .then(() => {
                setShowAddPlaza(false);
                getPlazas();
                toaster.success('Request is successful', {
                  description: 'Plaza created successfully',
                });
              })
              .catch((error) => {
                toaster.danger('Request failed', {
                  description:
                    error.response && error.response.data
                      ? error.response.data.message
                      : 'Unknown error',
                });
              });
            console.log(t);
          }}
          layout="vertical"
        >
          <Form.Item name="name" hasFeedback label="Plaza name" required>
            <Input required placeholder="Enter plaza name" />
          </Form.Item>
          <Form.Item name="region" label="Region" required>
            <Select onChange={(t) => setRegion(t)} placeholder="Select Region" bordered>
              {regions.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <button
              type="submit"
              disabled={creatingPlaza}
              onClick={() => {
                // setShowAddPlaza(true);
                // alert("hi")
              }}
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {' '}
              {creatingPlaza ? 'Loading' : 'Submit'}
            </button>
          </Form.Item>
        </Form>
      </Dialog>
    </Fragment>
  );
};

export default Plaza;
