import React from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/PuffLoader';
import { Result } from 'antd';

function EmptyState(args) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}
    >
      <Result
        {...args}
        icon={<img width={250} height={250} src={require('../assets/empty.png')} />}
      />
    </div>
  );
}

export default EmptyState;
