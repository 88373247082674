import {
  Button,
  Col,
  DatePicker,
  Select,
  Row,
  Drawer,
  Typography,
  Form,
  Input,
  notification,
  Card,
  Table,
  Calendar,
  Descriptions,
} from 'antd';
import moment from 'moment';
import useAxios from 'axios-hooks';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Header from '../../components/layout/header';
import config from '../../config';
import constants from '../../constants';
import { toaster } from 'evergreen-ui';
import axios from 'axios';
import { money } from '../../utils';

const { Option } = Select;

export default function Banking() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [plazas, setPlazas] = React.useState([]);
  const [plazaData, setPlazaData] = useState([]);
  const [bankingDate, setBankingDate] = useState(new Date().toDateString());
  const [nLoading, setNloading] = useState(false);
  const [monthly, setMonthly] = useState({});

  const [{ loading }, createBanking] = useAxios(
    {
      url: `${config}/api/v1/banking`,
      method: 'post',
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },
    { manual: true },
  );

  const [{ loading: loadingMonthly }, getMonthly] = useAxios({
    url: `${config}/api/v1/banking/month`,
    method: 'get',
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  useEffect(() => {
    getMonthly().then((d) => {
      setMonthly(d?.data.length > 0 && d?.data[0]);
    });
  }, []);

  const [{ data, loading: loadingBanking }, getBanking] = useAxios({
    url: `${config}/api/v1/banking`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  async function getPlazas() {
    const nP = JSON.parse(sessionStorage.getItem('plazas'));
    let npList = [];

    nP.map((item) => {
      npList.push(item?.name);
    });

    setPlazas(npList);
    setPlazaData(nP);
  }

  useEffect(() => {
    getPlazas();
  }, []);

  let [sessionData, setSessionDate] = useState([]);

  useEffect(() => {
    setSessionDate(
      plazas.length > 0 ? data.filter((item) => plazas.includes(item?.plaza?.name)) : data,
    );
  }, [data]);

  const columns = [
    {
      title: 'TransactionID',
      //   dataIndex: 'shift',
      render: (t) => (
        <Typography.Text>{t?._id.substring(t?._id.length, t?._id?.length - 6)}</Typography.Text>
      ),
    },
    {
      title: 'Plaza',
      dataIndex: 'plaza',
      render: (t) => <Typography.Text>{t?.name}</Typography.Text>,
    },
    {
      title: 'User',
      dataIndex: 'bankedBy',
      render: (t) => <Typography.Text>{t?.name}</Typography.Text>,
    },
    {
      title: 'Manual Rev.',
      render: (t) => (
        <Typography.Text>
          &cent;
          {t?.sessions?.manual}
        </Typography.Text>
      ),
    },
    {
      title: 'POS Rev.',
      render: (t) => (
        <Typography.Text>
          &cent;
          {t?.sessions?.amount}
        </Typography.Text>
      ),
    },
    {
      title: 'Total Rev. Generated',
      render: (t) => (
        <Typography.Text>
          &cent;
          {t?.sessions?.amount + t?.sessions?.manual}
        </Typography.Text>
      ),
    },

    {
      title: 'Amount Banked',
      render: (t) => (
        <Typography.Text>
          &cent;
          {t?.amount}
        </Typography.Text>
      ),
    },
    {
      title: 'Total Settled',
      render: (t) => <Typography.Text>&cent;{t?.sessions?.settledAmount}</Typography.Text>,
    },

    {
      title: 'Overage',
      render: (t) => (
        <Typography.Text
          style={{
            color:
              t?.sessions?.amount + t?.sessions?.manual - t?.sessions?.settledAmount > 0
                ? 'red'
                : 'green',
          }}
        >
          &cent;{t?.sessions?.amount + t?.sessions?.manual - t?.sessions?.settledAmount}
        </Typography.Text>
      ),
    },

    // {
    //   title: 'Arrears/Overate',
    //   render: (t) => <Typography.Text>&cent;{t?.sessions?.settledAmount}</Typography.Text>,
    // },

    {
      title: 'Bank Slip No.',
      render: (t) => <Typography.Text>{t?.bankSlipNo}</Typography.Text>,
    },
    {
      title: 'Revenue Bank Date',

      render: (t) => (
        <Typography.Text
        //   style={{ color: moment(t?.createdAt).isBefore(t?.shift?.endTime) ? 'red' : 'green' }}
        >
          {moment(t?.date).format('MMM, Do YYYY ')}
        </Typography.Text>
      ),
    },

    {
      title: 'Recorded On',

      render: (t) => (
        <Typography.Text
        //   style={{ color: moment(t?.createdAt).isBefore(t?.shift?.endTime) ? 'red' : 'green' }}
        >
          {moment(t?.createdAt).format('MMM, Do YYYY hh:mm A')}
        </Typography.Text>
      ),
    },
  ];

  return (
    <Fragment>
      <Header
        title="Banking"
        additional={() => (
          <Row>
            <Col>
              {sessionStorage.getItem('access') !== 'manager' && (
                <Button onClick={() => setShowDrawer(true)} type="primary">
                  Create Banking
                </Button>
              )}
            </Col>
            <Col style={{ marginLeft: 10 }}>
              <DatePicker.RangePicker
                onChange={async (t, ds) => {
                  setNloading(true);
                  try {
                    const n = await axios.get(
                      `${config}/api/v1/banking?startDate=${ds[0]}&endDate=${ds[1]}`,
                      {
                        headers: {
                          token: constants.token,
                          admintoken: sessionStorage.getItem('admintoken'),
                        },
                      },
                    );
                    setBankingDate(ds[0]);
                    const mm = await axios.get(
                      `${config}/api/v1/banking/month?startDate=${ds[0]}`,
                      {
                        headers: {
                          token: constants.token,
                          admintoken: sessionStorage.getItem('admintoken'),
                        },
                      },
                    );
                    setMonthly(mm?.data.length > 0 && mm?.data[0]);
                    setSessionDate(
                      plazas.length > 0
                        ? n.data.filter((item) => plazas.includes(item?.plaza?.name))
                        : n.data,
                    );
                  } catch (e) {
                    toaster.danger('Error', { description: e.message });
                  } finally {
                    setNloading(false);
                  }
                }}
              />
            </Col>
          </Row>
        )}
      />
      <div style={{ padding: '30px 240px' }}>
        <Row gutter={8}>
          <Col lg={18} sm={18}>
            <Table
              bordered
              dataSource={sessionData}
              loading={loadingBanking || nLoading}
              columns={columns}
              pagination={false}
            />
          </Col>
          <Col lg={6} sm={6}>
            <Card>
              <Calendar
                onChange={async (d) => {
                  setBankingDate(d.toDate().toDateString());
                  setNloading(true);
                  try {
                    const n = await axios.get(
                      `${config}/api/v1/banking?startDate=${d.format(
                        'YYYY-MM-DD',
                      )}&endDate=${d.format('YYYY-MM-DD')}`,
                      {
                        headers: {
                          token: constants.token,
                          admintoken: sessionStorage.getItem('admintoken'),
                        },
                      },
                    );

                    const mm = await axios.get(
                      `${config}/api/v1/banking/month?startDate=${d.format('YYYY/MM/DD')}`,
                      {
                        headers: {
                          token: constants.token,
                          admintoken: sessionStorage.getItem('admintoken'),
                        },
                      },
                    );
                    setMonthly(mm?.data.length > 0 && mm?.data[0]);
                    console.log(mm.data);
                    setSessionDate(
                      plazas.length > 0
                        ? n.data.filter((item) => plazas.includes(item?.plaza?.name))
                        : n.data,
                    );
                  } catch (e) {
                    toaster.danger('Error', { description: e.message });
                  } finally {
                    setNloading(false);
                  }
                }}
                fullscreen={false}
              />
            </Card>

            <Card style={{ marginTop: 10 }} title={moment(bankingDate).format('MMMM, YYYY')}>
              <Descriptions column={2} layout="vertical">
                <Descriptions.Item label="Manual">&cent;{money(monthly?.manual)}</Descriptions.Item>
                <Descriptions.Item label="POS Rev">
                  &cent;{money(monthly?.amount)}
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                  &cent; {money(monthly?.manual + monthly?.amount)}
                </Descriptions.Item>
                <Descriptions.Item label="Total Settled">
                  &cent; {money(monthly?.settledAmount)}
                </Descriptions.Item>
                <Descriptions.Item label="Total Banked">{money(monthly?.banked)}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
      <Drawer
        width={600}
        title={<Typography.Title>Add Banking</Typography.Title>}
        destroyOnClose
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <Form
          layout="vertical"
          onFinish={(t) => {
            if (t?.amount !== t?.repeatAmount) {
              notification.error({
                message: 'Amount does not match with repeat amount',
                placement: 'bottomRight',
              });

              return;
            }

            const payload = {
              ...t,
              date: t?.date.format('MM/DD/YYYY'),
              amount: Number(t?.amount),
            };

            createBanking({ data: { ...payload } })
              .then(() => {
                toaster.success('Request Sucessfully', {
                  description: 'Banking has been recorded successfully',
                });
                getBanking();
                setShowDrawer(false);
              })
              .catch((e) => {
                toaster.danger('Something went wrong', {
                  description: e.message,
                });
              });
          }}
        >
          <Form.Item
            rules={[{ required: true, message: 'Please select the date for banking' }]}
            name="date"
            required
            label="Which Date are you banking for?"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "You're required to select your plaza" }]}
            name="plaza"
            required
            label="Select Plaza"
          >
            <Select placeholder="Please select a plaza you're banking for">
              {plazaData.map((item, index) => (
                <Option key={index.toString()} value={item?._id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Amount" name="amount" hasFeedback rules={[{ min: 0, required: true }]}>
            <Input placeholder="Enter amount to be banked" allowClear inputMode="decimal" />
          </Form.Item>
          <Form.Item
            label="Repeat Amount"
            name="repeatAmount"
            hasFeedback
            rules={[{ min: 0, required: true }]}
          >
            <Input placeholder="Repeat amount here" allowClear inputMode="decimal" />
          </Form.Item>
          <Form.Item label="Bank Slip No." name="bankSlipNo" hasFeedback>
            <Input placeholder="Enter bank slip number" allowClear />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Fragment>
  );
}
