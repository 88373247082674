import React, { Fragment, useEffect, useState, useRef } from 'react';
import Header from '../../components/layout/header';
import {
  DatePicker,
  Row,
  Col,
  Select,
  Empty,
  Descriptions,
  Divider,
  Button,
  Drawer,
  Typography,
  Table,
  PageHeader,
} from 'antd';
import { Spinner } from 'evergreen-ui';
import useAxios from 'axios-hooks';
import ReactToPrint from 'react-to-print';
import constants from '../../constants';
import config from '../../config';

class ToBePrinted extends React.Component {
  getShiftTotalAmount = (session) => {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;
    return (
      salon * 5 +
      motor * 0.5 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5
    );
  };

  render() {
    const { vehicleData, data = [], formData, plaza, groupBy } = this.props;

    return vehicleData.total ? (
      <div style={{}}>
        <center>
          <h1>Plaza Report</h1>
        </center>
        <center>
          <h2>
            {formData.startDate} - {formData.endDate}
          </h2>
        </center>
        <Divider>{plaza}</Divider>

        <Descriptions bordered size="small" layout="vertical">
          <Descriptions.Item label="Total Number of Shifts"> {data?.length}</Descriptions.Item>

          <Descriptions.Item label="Total Amount Made">
            GH&cent; {Number(vehicleData.total.amount.toFixed(2)).toLocaleString('en')}
          </Descriptions.Item>
          <Descriptions.Item label="Total Number of Vehicles">
            {vehicleData.total.vehiclesCount.toLocaleString('en')}
          </Descriptions.Item>
          <Descriptions.Item label="Motors">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.motor.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.motor.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Tricycles">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.tricycle.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.tricycle.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Salon Cars">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.salon.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.salon.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Pickup">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.pickup.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.pickup.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Mini Truck">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.minitrk.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.minitrk.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Truck Bus">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.trkbus.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.trkbus.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Heavy Duty">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.hvyduty.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                GH&cent; {Number(vehicleData.hvyduty.amount.toFixed(2)).toLocaleString('en')}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Exempt">
            <Descriptions bordered layout="vertical">
              <Descriptions.Item label="Total">
                {vehicleData.exempt.vehiclesCount.toLocaleString('en')}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">GH&cent; 0.00</Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>
        <h1>hi</h1>
        <div className="flex flex-col" style={{ marginTop: '20px' }}>
          <div className="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-2 font-small text-gray-500 uppercase tracking-wider">
                      Plaza
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-2 font-small text-gray-500 uppercase tracking-wider">
                      Motor
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-2 font-small text-gray-500 uppercase tracking-wider">
                      Salon
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                      Tricycle
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                      Minitruck
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                      Trk Bus
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                      Hvy Duty
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                      Pickup
                    </th>
                    <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                  </tr>
                  {/* <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Plaza
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Shift ID
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      POS ID
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Booth
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      User
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Uploaded At
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                  </tr> */}
                </thead>
                <tbody className="bg-white">
                  {/* {this.props.groupBy === 'user' &&
                    data.map((session) => {
                      return (
                        <>
                          <tr>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.user.plaza.name}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.shiftID}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.posID}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.booth}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              GHc {this.getShiftTotalAmount(session).toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                              {session.user.fullName}
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                              {new Date(session.createdAt).toDateString()}
                            </td>
                          </tr>
                        </>
                      );
                    })} */}

                  {data?.map((plaza) => {
                    return (
                      <>
                        <tr>
                          <td className="px-4 py-2  border-b border-gray-200 text-sm leading-2 font-small text-gray-900">
                            {plaza.plaza}
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            {/* {session.shiftID} */}
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.motor?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.motor?.amount.toFixed(2)}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.salon?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.salon?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.tricycle?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.tricycle?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.minitrk?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.minitrk?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.trkbus?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.trkbus?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.hvyduty?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.hvyduty?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item
                                style={{ fontSize: 10 }}
                                style={{ fontSize: 10 }}
                                label="No."
                              >
                                {plaza?.data?.pickup?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item
                                style={{ fontSize: 10 }}
                                style={{ fontSize: 10 }}
                                label="Ghc"
                              >
                                {plaza?.data?.pickup?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                          <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            <Descriptions bordered size="small" layout="vertical">
                              <Descriptions.Item style={{ fontSize: 10 }} label="No.">
                                {plaza?.data?.total?.vehiclesCount}
                              </Descriptions.Item>
                              <Descriptions.Item style={{ fontSize: 10 }} label="Ghc">
                                {plaza?.data?.total?.amount}
                              </Descriptions.Item>
                            </Descriptions>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

class IndividualSummaryPrints extends React.Component {
  render() {
    return (
      <div>
        <center>
          <Typography.Title>Report</Typography.Title>
          <Typography.Title type="secondary" level={3}>
            {this.props?.data?.plaza}
          </Typography.Title>
        </center>
        <Table pagination={false} dataSource={this.props?.data?.shifts} columns={userTable} />
      </div>
    );
  }
}
const getShiftTotalAmount = (session) => {
  const {
    salon,
    pickup,
    minitrk,
    trkbus,
    hvyduty,
    exempt,
    motor,
    tricycle,
  } = session.vehiclesCount;

  let tAMount = 0;
  return {
    tAMount:
      salon * 5 +
      motor * 0.5 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5,
    total: salon + motor + tricycle + pickup + minitrk + trkbus + hvyduty,
  };
};

const vehicleClassifications = [
  {
    name: 'Motor',
    key: 'motor',
    amount: 0.5,
  },
  {
    name: 'Tricycle',
    key: 'tricycle',
    amount: 0.5,
  },
  {
    name: 'Salon',
    key: 'salon',
    amount: 5,
  },
  {
    name: 'Pickup',
    key: 'pickup',
    amount: 1.0,
  },
  {
    name: 'Mini Truck / Bus',
    key: 'minitrk',
    amount: 1.5,
  },
  {
    name: 'Truck / Bus',
    key: 'trkbus',
    amount: 2.0,
  },
  {
    name: 'Heavy Duty',
    key: 'hvyduty',
    amount: 2.5,
  },
  {
    name: 'Exempt',
    key: 'exempt',
  },
];

const userTable = [
  {
    title: 'Total',
    render: (item) => <span> &cent;{getShiftTotalAmount(item).tAMount}</span>,
    responsive: ['md'],
  },
  {
    title: 'Booth ID',
    render: (item) => <span>{item?.booth}</span>,
    responsive: ['md'],
  },
  {
    title: 'Shift ID',
    render: (item) => <span>{item?.shiftID}</span>,
    responsive: ['md'],
  },
  {
    title: 'POS ID',
    render: (item) => <span>{item?.posID}</span>,
    responsive: ['md'],
  },
  {
    title: 'User',
    render: (item) => <span>{item?.user?.fullName}</span>,
    responsive: ['md'],
  },
  {
    title: 'Start Time',
    render: (item) => <span>{item?.startTime}</span>,
    responsive: ['md'],
  },
  {
    title: 'End Time',
    render: (item) => <span>{item?.endTime}</span>,
    responsive: ['md'],
  },
];

function Reports() {
  const [formData, setFormData] = useState({
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
    classifications: [],
  });

  const [plaza, setPlaza] = useState(null);
  const [plazas, setPlazas] = useState([]);
  const [groupBy, setGroupBy] = useState('plaza');

  const [vehicleData, setVehicleData] = useState({});
  const [showShiftDetails, setShowShiftDetails] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);

  const [localPlazas, setLocalPlazas] = React.useState([]);

  async function getPlazas() {
    const nP = JSON.parse(sessionStorage.getItem('plazas'));
    let npList = [];
    nP.map((item) => {
      npList.push(item?.name);
    });
    setLocalPlazas(npList);
  }

  useEffect(() => {
    getPlazas();
  }, []);

  const [{ data: pzData, loading: loadingPlaza, error: errorLoadingPlaza }] = useAxios({
    url: `${config}/api/v1/plaza`,
    headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
  });

  const [{ data: ReportData, loading: l1, error: report1Error }, generateReport] = useAxios(
    {
      url: `${config}/api/v1/session/${plaza}?startDate=${formData.startDate}&endDate=${formData.endDate}`,
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },

    {
      manual: true,
    },
  );

  const [{ data: reportData, loading: l2, error: report2Error }, generateReportAll] = useAxios(
    {
      url: `${config}/api/v1/session?startDate=${formData.startDate}&endDate=${formData.endDate}`,
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
    },
    {
      manual: true,
    },
  );

  function groupItemBy(array, property) {
    var hash = {},
      props = property.split('.');
    for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  }

  let data = plaza ? ReportData : reportData;
  const loading = l1 || l2;

  let grouped;

  if (data) {
    if (groupBy === 'plaza') {
      grouped = Object.values(groupItemBy(data, 'user.plaza.name'));
    } else {
      grouped = Object.values(groupItemBy(data, 'user.fullName'));
    }
  }

  let groupedData = [];

  const groupAllPlazaVehicleCount = (grouped) => {
    let vehicleCountsArray = [];

    grouped.map((plazaData) => {
      let vData;
      let p;
      let vCountData = [];
      let shifts = [];
      plazaData.map((shift) => {
        vData = groupBy === 'plaza' ? shift.plaza.name : shift.user.fullName;
        vCountData.push(shift.vehiclesCount);
        p = shift.user.plaza.name;
        shifts.push(shift);
      });
      vehicleCountsArray.push({ plaza: vData, vehiclesCount: vCountData, p: p });
      groupedData.push({ plaza: vData, shifts: shifts, p: p });
    });

    let allDataGrouped = [];

    vehicleCountsArray.map((vehicleObject) => {
      const vehicleData = {
        total: {
          vehiclesCount: 0,
          amount: 0,
        },
        salon: {
          vehiclesCount: 0,
          amount: 0,
        },
        motor: {
          vehiclesCount: 0,
          amount: 0,
        },
        tricycle: {
          vehiclesCount: 0,
          amount: 0,
        },
        pickup: {
          vehiclesCount: 0,
          amount: 0,
        },
        minitrk: {
          vehiclesCount: 0,
          amount: 0,
        },
        trkbus: {
          vehiclesCount: 0,
          amount: 0,
        },
        hvyduty: {
          vehiclesCount: 0,
          amount: 0,
        },
        exempt: {
          vehiclesCount: 0,
          amount: 0,
        },
      };
      vehicleObject.vehiclesCount.map((vehicles) => {
        vehicleClassifications.map((classification) => {
          vehicleData[classification.key]['vehiclesCount'] += vehicles[classification.key];
          vehicleData[classification.key]['amount'] +=
            vehicles[classification.key] * classification.amount;
        });
      });
      vehicleData.total.vehiclesCount =
        vehicleData.salon.vehiclesCount +
        vehicleData.motor.vehiclesCount +
        vehicleData.tricycle.vehiclesCount +
        vehicleData.pickup.vehiclesCount +
        vehicleData.minitrk.vehiclesCount +
        vehicleData.trkbus.vehiclesCount +
        vehicleData.hvyduty.vehiclesCount +
        vehicleData.exempt.vehiclesCount;

      vehicleData.total.amount =
        vehicleData.salon.amount +
        vehicleData.pickup.amount +
        vehicleData.motor.amount +
        vehicleData.tricycle.amount +
        vehicleData.minitrk.amount +
        vehicleData.trkbus.amount +
        vehicleData.hvyduty.amount;
      allDataGrouped.push({ plaza: vehicleObject.plaza, data: vehicleData, d: vehicleObject.p });
    });

    // console.log(allDataGrouped);
    return allDataGrouped;
  };

  const groupedSummary =
    grouped && localPlazas.length > 0
      ? groupAllPlazaVehicleCount(grouped)
          .sort((a, b) => a?.data?.total?.amount - b?.data?.total?.amount)
          .reverse()
          .filter((item) => localPlazas.includes(item?.d))
      : grouped &&
        groupAllPlazaVehicleCount(grouped)
          .sort((a, b) => a?.data?.total?.amount - b?.data?.total?.amount)
          .reverse();

  let plazaData =
    pzData && localPlazas.length > 0
      ? pzData.filter((item) => localPlazas.includes(item?.name))
      : pzData;

  useEffect(() => {
    data =
      data && localPlazas.length > 0
        ? data.filter((item) => localPlazas.includes(item?.plaza?.name))
        : data;
  }, [plazaData]);

  useEffect(() => {
    if (data) {
      const vehicleData = {
        total: {
          vehiclesCount: 0,
          amount: 0,
        },
        salon: {
          vehiclesCount: 0,
          amount: 0,
        },
        motor: {
          vehiclesCount: 0,
          amount: 0,
        },
        tricycle: {
          vehiclesCount: 0,
          amount: 0,
        },
        pickup: {
          vehiclesCount: 0,
          amount: 0,
        },
        minitrk: {
          vehiclesCount: 0,
          amount: 0,
        },
        trkbus: {
          vehiclesCount: 0,
          amount: 0,
        },
        hvyduty: {
          vehiclesCount: 0,
          amount: 0,
        },
        exempt: {
          vehiclesCount: 0,
          amount: 0,
        },
      };

      const vehicles = data.map(function (session) {
        return session.vehiclesCount;
      });
      //  console.log(vehicles);

      // Using vehicle classification to extract and calc data
      vehicles.map((vehicleObject) => {
        vehicleClassifications.map((classification) => {
          vehicleData[classification.key]['vehiclesCount'] += vehicleObject[classification.key];
          vehicleData[classification.key]['amount'] +=
            vehicleObject[classification.key] * classification.amount;
        });
      });

      // Using vehicle data to calculate total data
      vehicleData.total.vehiclesCount =
        vehicleData.salon.vehiclesCount +
        vehicleData.motor.vehiclesCount +
        vehicleData.tricycle.vehiclesCount +
        vehicleData.pickup.vehiclesCount +
        vehicleData.minitrk.vehiclesCount +
        vehicleData.trkbus.vehiclesCount +
        vehicleData.hvyduty.vehiclesCount +
        vehicleData.exempt.vehiclesCount;

      vehicleData.total.amount =
        vehicleData.salon.amount +
        vehicleData.pickup.amount +
        vehicleData.motor.amount +
        vehicleData.tricycle.amount +
        vehicleData.minitrk.amount +
        vehicleData.trkbus.amount +
        vehicleData.hvyduty.amount;

      setVehicleData(vehicleData);
    }
    // console.log(plazaData);
    setPlazas(plazaData);
    setPlazas(plazaData);
  }, [data]);

  const componentRef = useRef();
  const reportPrintRef = useRef();

  const getShiftTotalAmount = (session) => {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;

    let tAMount = 0;
    return {
      tAMount:
        salon * 0.5 +
        motor * 0.1 +
        tricycle * 0.5 +
        pickup * 1 +
        minitrk * 1.5 +
        trkbus * 2 +
        hvyduty * 2.5,
      total: salon + motor + tricycle + pickup + minitrk + trkbus + hvyduty,
    };
  };

  return (
    <Fragment>
      {/* <Header title="Reports" /> */}

      <div style={{ padding: '30px 240px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h1>Select your configurations to generate report</h1>

          {!data && !vehicleData.total ? null : (
            <ReactToPrint
              pageStyle={`
@media print 
{
    @page {
      
      margin:10mm;
     
    }
    html, body {
        
      
        font-size: 10px;
        background: #FFF;
      
    }
    body {
        padding-top:15mm;
    }
}

               table { page-break-inside:auto }
    tr    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group, margin-top: 20mm }
    tfoot { display:table-footer-group }
              `}
              trigger={() => (
                <button
                  type="button"
                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
            />
          )}
          <button
            type="submit"
            className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            onClick={() => {
              if (plaza) {
                // reportData = undefined;
                data = undefined;
                generateReport();
              } else {
                // ReportData = undefined;
                data = undefined;
                generateReportAll();
              }
            }}
          >
            {loading ? <Spinner /> : 'Generate Report'}
          </button>
        </div>

        {!data && !vehicleData.total ? null : (
          <div style={{ display: 'none' }}>
            <ToBePrinted
              ref={componentRef}
              vehicleData={vehicleData}
              data={groupedSummary}
              formData={formData}
              groupBy={groupBy}
              plaza={plaza}
            />
          </div>
        )}
        <Row gutter={50} style={{ marginTop: '20px' }}>
          <Col>
            <p>Select start and end date</p>
            <DatePicker.RangePicker
              onChange={([startDate, endDate]) => {
                setFormData({
                  ...formData,
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                });
              }}
            />
          </Col>
          <Col>
            <p>Select plazas to generate report for..</p>
            <Select
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={[]}
              onChange={(classifications) => {
                setPlaza(classifications);
                //   setFormData({
                //     ...formData,
                //  //   classifications,
                //   });
              }}
            >
              {plazaData &&
                plazaData.map((classification) => (
                  <Select.Option value={classification.name}>{classification.name}</Select.Option>
                ))}
            </Select>
          </Col>
          <Col>
            <p>Group By:</p>
            <Select
              style={{ width: '100%' }}
              value={groupBy}
              onChange={(t) => {
                setGroupBy(t);
              }}
            >
              <Select.Option value="plaza">Plaza</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
          </Col>
          <Col>
            <p>Clear Data</p>
            <Button
              onClick={() => {
                setPlaza(null);
                data = undefined;
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        {!data || !vehicleData.total ? (
          <EmptyComponent />
        ) : (
          <div style={{ marginTop: '30px' }}>
            <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Total Number Of Shifts
                    </dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        {data.length}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Total Amount Made
                    </dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        GHc {Number(vehicleData.total.amount.toFixed(2)).toLocaleString('en')}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Total Number of Vehicles
                    </dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        {vehicleData.total.vehiclesCount.toLocaleString('en')}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Salon - {vehicleData.salon.vehiclesCount.toLocaleString('en')}
                    </dt>

                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        GHc {Number(vehicleData.salon.amount.toFixed(2)).toLocaleString('en')}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Pickup - {vehicleData.pickup.vehiclesCount.toLocaleString('en')}
                    </dt>

                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        GHc {Number(vehicleData.pickup.amount.toFixed(2)).toLocaleString('en')}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Mini Truck - {vehicleData.minitrk.vehiclesCount.toLocaleString('en')}
                    </dt>

                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        GHc {Number(vehicleData.minitrk.amount.toFixed(2)).toLocaleString('en')}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <dl>
                    <dt className="text-base leading-6 font-normal text-gray-900">
                      Truck Bus - {vehicleData.trkbus.vehiclesCount.toLocaleString('en')}
                    </dt>

                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                        GHc {Number(vehicleData.trkbus.amount.toFixed(2)).toLocaleString('en')}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div>
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-base leading-6 font-normal text-gray-900">
                        Heavy Duty - {vehicleData.hvyduty.vehiclesCount.toLocaleString('en')}
                      </dt>

                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                          GHc {Number(vehicleData.hvyduty.amount.toFixed(2)).toLocaleString('en')}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div>
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-base leading-6 font-normal text-gray-900">
                        Exempt - {vehicleData.exempt.vehiclesCount.toLocaleString('en')}
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                          GHc 0.00
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
              <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div>
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-base leading-6 font-normal text-gray-900">
                        Tricycle - {vehicleData.tricycle.vehiclesCount.toLocaleString('en')}
                      </dt>

                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                          GHc {Number(vehicleData.tricycle.amount.toFixed(2)).toLocaleString('en')}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div>
                  <div className="px-4 py-5 sm:p-6">
                    <dl>
                      <dt className="text-base leading-6 font-normal text-gray-900">
                        Motor - {vehicleData.motor.vehiclesCount.toLocaleString('en')}
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
                          GHc {Number(vehicleData.motor.amount.toFixed(2)).toLocaleString('en')}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ marginTop: '20px' }}>
              <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-2 font-small text-gray-500 uppercase tracking-wider">
                          Plaza
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-2 font-small text-gray-500 uppercase tracking-wider">
                          Motor
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-2 font-small text-gray-500 uppercase tracking-wider">
                          Salon
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                          Tricycle
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                          Minitruck
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                          Trk Bus
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                          Hvy Duty
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                          Pickup
                        </th>
                        <th className="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {groupedSummary?.map((plaza) => {
                        return (
                          <>
                            <tr
                              onClick={() => {
                                const selected = groupedData.find(
                                  (item) => item.plaza === plaza.plaza,
                                );
                                if (selected !== -1) {
                                  setSelectedShift(selected);
                                  console.log('Selected Shift', selected);
                                }
                                setShowShiftDetails(true);
                              }}
                            >
                              <td className="px-4 py-2  border-b border-gray-200 text-sm leading-2 font-small text-gray-900">
                                {plaza.plaza}
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                {/* {session.shiftID} */}
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.motor?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.motor?.amount.toFixed(2)}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.salon?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.salon?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.tricycle?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.tricycle?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.minitrk?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.minitrk?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.trkbus?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.trkbus?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.hvyduty?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.hvyduty?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.pickup?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.pickup?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                              <td className="px-2 py-3 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                                <Descriptions bordered size="small" layout="vertical">
                                  <Descriptions.Item label="No.">
                                    {plaza?.data?.total?.vehiclesCount}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Ghc">
                                    {plaza?.data?.total?.amount}
                                  </Descriptions.Item>
                                </Descriptions>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer
        visible={showShiftDetails}
        onClose={() => setShowShiftDetails(false)}
        placement="bottom"
        height={window.screen.height / 1.2}
      >
        {selectedShift ? (
          <div>
            <PageHeader
              title={<Typography.Title>{selectedShift.plaza}</Typography.Title>}
              extra={
                <div>
                  {' '}
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type="button"
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                      >
                        Print
                      </button>
                    )}
                    content={() => reportPrintRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <IndividualSummaryPrints ref={reportPrintRef} data={selectedShift} />
                  </div>
                </div>
              }
            />

            <Table pagination={false} dataSource={selectedShift?.shifts} columns={userTable} />
          </div>
        ) : (
          <Empty />
        )}
      </Drawer>
    </Fragment>
  );
}

function EmptyComponent() {
  return (
    <div style={{ marginTop: '100px' }}>
      <Empty />
    </div>
  );
}

export default Reports;
