import React from 'react';
import { SideSheet } from 'evergreen-ui';
import useAxios from 'axios-hooks';
import { toaster } from 'evergreen-ui';
import constants from '../../../constants';
import config from '../../../config';

function AddPlazaUser({ onCloseComplete, isShown, plaza }) {
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    role: 'cashier',
    name: '',
  });

  const [{ loading, data, error }, registerUser] = useAxios(
    {
      url: `${config}/api/v1/user/register`,
      method: 'post',
      headers: { token: constants.token, admintoken: sessionStorage.getItem('admintoken') },
      data: {
        ...formData,
        fullName: formData.lastName + ' ' + formData.firstName,
        plaza,
      },
    },
    { manual: true },
  );

  if (data) {
    toaster.success('Request is successful', {
      description: 'User has been created',
    });
    onCloseComplete();
  }

  if (error) {
    toaster.danger('Request failed', {
      description:
        error.response && error.response.data ? error.response.data.message : 'Unknown error',
    });
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ padding: '30px' }}>
        <h3>Add User In {plaza} Plaza</h3>

        <div style={{ marginTop: '50px' }}>
          <div className="mt-10 sm:mt-0">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  registerUser();
                }}
              >
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        First name
                      </label>
                      <input
                        required
                        value={formData.firstName}
                        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        id="first_name"
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Last name
                      </label>
                      <input
                        required
                        value={formData.lastName}
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        id="last_name"
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label className="block text-sm font-medium leading-5 text-gray-700">
                        Phone Number
                      </label>
                      <input
                        required
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        type="tel"
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label className="block text-sm font-medium leading-5 text-gray-700">
                        Username
                      </label>
                      <input
                        required
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        type="tel"
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label className="block text-sm font-medium leading-5 text-gray-700">
                        Role
                      </label>
                      <select
                        required
                        value={formData.role}
                        onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                        className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      >
                        <option value="admin">Admin</option>
                        <option value="cashier">Cashier</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out"
                  >
                    {loading ? 'Loading...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SideSheet>
  );
}

export default AddPlazaUser;
