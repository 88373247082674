import React, { Fragment } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';

import Login from './pages/auth/login';
import Layout from './components/layout/layout';

function App() {
  return (
    <Fragment>
      <HashRouter>
        <Switch>
          <Route exact path={'/login'} name={'login'} render={(props) => <Login {...props} />} />
          <Route path={'/'} render={(props) => <Layout {...props} name={'Dashboard'} />} />
        </Switch>
      </HashRouter>
    </Fragment>
  );
}

export default App;
