import React, { useRef } from 'react';
import { SideSheet } from 'evergreen-ui';
import { Descriptions } from 'antd';
import ReactToPrint from 'react-to-print';

class ToBePrinted extends React.Component {
  render() {
    const { session } = this.props;
    let total = 0;
    let totalAmount = 0;
    let cash = 0;
    let loyalty = 0;
    if (typeof session.cash === 'number') {
      console.log(typeof session.cash);
      cash = cash + session?.cash;
      loyalty = loyalty + session?.loyalty;
    }
    if (session) {
      const {
        salon,
        pickup,
        minitrk,
        trkbus,
        hvyduty,
        exempt,
        motor,
        tricycle,
      } = session.vehiclesCount;
      total = salon + pickup + minitrk + trkbus + hvyduty + exempt + motor + tricycle;
      totalAmount =
        salon * 5 +
        motor * 0.5 +
        tricycle * 0.5 +
        pickup * 1 +
        minitrk * 1.5 +
        trkbus * 2 +
        hvyduty * 2.5;
    }

    return (
      <div style={{}}>
        <center>
          <h2 style={{ color: '#190840' }}>Shift Details</h2>
          <p style={{ color: '#190840' }}>{session.plaza.name}</p>
        </center>

        <div className="mt-5 border-t border-gray-200 pt-5">
          <dl>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Start Time
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {new Date(session.startTime).toLocaleDateString()} at{' '}
                  {new Date(session.startTime).toLocaleTimeString()}
                </dd>
              </div>

              <div style={{ flex: 1, textAlign: 'center', color: '#190840' }}>
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  End Time
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {new Date(session.endTime).toLocaleDateString()} at{' '}
                  {new Date(session.endTime).toLocaleTimeString()}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ color: '#190840', flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Shift ID
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.shiftID}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ color: '#190840', flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  POS ID
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.posID}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Booth
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.booth}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Amount
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc{' '}
                  {session?.manual
                    ? (session?.manual + totalAmount).toFixed(2)
                    : totalAmount.toFixed(2)}
                </dd>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Cash
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  Ghc {cash.toFixed(2)}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Manual
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc {session?.manual || 0.0}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Loyalty
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  GHc {loyalty.toFixed(2)}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  User Full Name
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.user.fullName}
                </dd>
              </div>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  User Telephone
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {session.user.phone}
                </dd>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                style={{ flex: 1, textAlign: 'center' }}
              >
                <dt
                  style={{ color: '#190840' }}
                  className="text-sm leading-5 font-medium text-gray-500"
                >
                  Uploaded At
                </dt>
                <dd
                  style={{ color: '#190840' }}
                  className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                >
                  {new Date(session.updatedAt).toDateString()} at{' '}
                  {new Date(session.updatedAt).toLocaleTimeString()}
                </dd>
              </div>
            </div>
          </dl>

          <div
            style={{
              marginTop: '30px',
              marginLeft: '40px',
              marginRight: '40px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="flex"
          >
            <table className="flex-1 table-auto">
              <thead>
                <tr>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Vehicle Type
                  </th>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Quantity
                  </th>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Charge
                  </th>
                  <th style={{ color: '#190840' }} className="px-4 py-2">
                    Total
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Motor
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.motor}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0.5
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.motor * 0.5).toFixed(2)}
                  </td>
                </tr>
                {/* <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Tricycle
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.tricycle}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.tricycle * 0.5).toFixed(2)}
                  </td>
                </tr> */}
                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Salon
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.salon}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    5.0
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.salon * 5).toFixed(2)}
                  </td>
                </tr>

                {/* <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Pickup
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.pickup}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    1.00
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.pickup * 1).toFixed(2)}
                  </td>
                </tr> */}

                {/* <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Minitruck
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.minitrk}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    1.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.minitrk * 1.5).toFixed(2)}
                  </td>
                </tr> */}

                {/* <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Truck / Bus
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.trkbus}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    2.00
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.trkbus * 2).toFixed(2)}
                  </td>
                </tr> */}

                {/* <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Heavy Duty
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.hvyduty}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    2.50
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.hvyduty * 2.5).toFixed(2)}
                  </td>
                </tr> */}

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    Exempt
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {session.vehiclesCount.exempt}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    0
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {(session.vehiclesCount.hvyduty * 0).toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td style={{ color: '#190840' }} className="border px-4 py-2"></td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {total}
                  </td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2"></td>
                  <td style={{ color: '#190840' }} className="border px-4 py-2">
                    {totalAmount.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <Descriptions
              bordered
              size="small"
              layout="vertical"
              column={1}
              style={{ marginTop: '10px', marginLeft: '20px' }}
              title="Car Counts"
            >
              <Descriptions.Item label="System Counts(a)">
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item style={{ color: '#190840' }} label="b">
                    {session.loopCounts.a}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ color: '#190840' }} label="s">
                    {session.loopCounts.b}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ color: '#190840' }} label="Average">
                    {Math.round((session.loopCounts.a + session.loopCounts.b) / 2)}
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>
              <Descriptions.Item style={{ color: '#190840' }} label="Collector's Count(b)">
                {total}
              </Descriptions.Item>
              <Descriptions.Item style={{ color: '#190840' }} label="Descrepency(a-b)">
                {Math.round((session.loopCounts.a + session.loopCounts.b) / 2) - total}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    );
  }
}

function ViewSession({ onCloseComplete, isShown, session }) {
  let total = 0;
  let totalAmount = 0;
  let cash = 0;
  let loyalty = 0;

  if (session) {
    const {
      salon,
      pickup,
      minitrk,
      trkbus,
      hvyduty,
      exempt,
      motor,
      tricycle,
    } = session.vehiclesCount;
    if (typeof session.cash === 'number') {
      console.log(typeof session.cash);
      cash = cash + session?.cash;
      loyalty = loyalty + session?.loyalty;
    }

    total = salon + pickup + minitrk + trkbus + hvyduty + exempt + motor + tricycle;
    totalAmount =
      salon * 5 +
      motor * 1 +
      tricycle * 0.5 +
      pickup * 1 +
      minitrk * 1.5 +
      trkbus * 2 +
      hvyduty * 2.5;
  }
  console.log(session.vehiclesCount);
  const componentRef = useRef();

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      {!session ? null : (
        <div style={{ padding: '30px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h3>Shift Details</h3>
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div style={{ display: 'none' }}>
            <ToBePrinted ref={componentRef} session={session} />
          </div>

          <div className="mt-5 border-t border-gray-200 pt-5">
            <dl>
              <div className="lg:grid lg:grid-cols-2 sm:gap-4">
                <div>
                  <dt className="text-sm leading-5 font-medium text-gray-500">Start Time</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {new Date(session.startTime).toLocaleDateString()} at{' '}
                    {new Date(session.startTime).toLocaleTimeString()}
                  </dd>
                </div>

                <div>
                  <dt className="text-sm leading-5 font-medium text-gray-500">End Time</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {new Date(session.endTime).toLocaleDateString()} at{' '}
                    {new Date(session.endTime).toLocaleTimeString()}
                  </dd>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-2 sm:gap-4">
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Shift ID</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {session.shiftID}
                  </dd>
                </div>

                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">POS ID</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {session.posID}
                  </dd>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-2 sm:gap-4">
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Booth</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {session.booth}
                  </dd>
                </div>
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Amount</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    GHc{' '}
                    {session?.manual
                      ? (session.totalAmount + session?.manual).toFixed(2)
                      : session?.cash + session.loyalty}
                  </dd>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-2 sm:gap-4">
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Cash</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    Ghc {cash.toFixed(2)}
                  </dd>
                </div>
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Loyalty</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    GHc {loyalty.toFixed(2)}
                  </dd>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-2 sm:gap-4">
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">Manual</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    Ghc {session?.manual || 0.0}
                  </dd>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-2 sm:gap-4">
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">User Full Name</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {session.user.fullName}
                  </dd>
                </div>
                <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <dt className="text-sm leading-5 font-medium text-gray-500">User Telephone</dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    {session.user.phone}
                  </dd>
                </div>
              </div>

              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <dt className="text-sm leading-5 font-medium text-gray-500">Uploaded At</dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  {new Date(session.updatedAt).toDateString()} at{' '}
                  {new Date(session.updatedAt).toLocaleTimeString()}
                </dd>
              </div>
            </dl>

            <div style={{ marginTop: '50px' }}>
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Vehicle Type</th>
                    <th className="px-4 py-2">Quantity</th>
                    <th className="px-4 py-2">Charge</th>
                    <th className="px-4 py-2">Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="border px-4 py-2">Motor</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.motor}</td>
                    <td className="border px-4 py-2">0.50</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.motor * 0.5).toFixed(2)}
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="border px-4 py-2">Motor</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.tricycle}</td>
                    <td className="border px-4 py-2">0.5</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.tricycle * 0.5).toFixed(2)}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="border px-4 py-2">Cars</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.salon}</td>
                    <td className="border px-4 py-2">5.00</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.salon * 5).toFixed(2)}
                    </td>
                  </tr>
                  {/* 
                  <tr>
                    <td className="border px-4 py-2">4x4</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.pickup}</td>
                    <td className="border px-4 py-2">1.00</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.pickup * 1).toFixed(2)}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td className="border px-4 py-2">Minitruck</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.minitrk}</td>
                    <td className="border px-4 py-2">1.50</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.minitrk * 1.5).toFixed(2)}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td className="border px-4 py-2">Truck / Bus</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.trkbus}</td>
                    <td className="border px-4 py-2">2.00</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.trkbus * 2).toFixed(2)}
                    </td>
                  </tr> */}
                  {/* 
                  <tr>
                    <td className="border px-4 py-2">Heavy Duty</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.hvyduty}</td>
                    <td className="border px-4 py-2">2.50</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.hvyduty * 2.5).toFixed(2)}
                    </td>
                  </tr> */}

                  <tr>
                    <td className="border px-4 py-2">Exempt</td>
                    <td className="border px-4 py-2">{session.vehiclesCount.exempt}</td>
                    <td className="border px-4 py-2">0</td>
                    <td className="border px-4 py-2">
                      {(session.vehiclesCount.hvyduty * 0).toFixed(2)}
                    </td>
                  </tr>

                  <tr>
                    <td className="border px-4 py-2"></td>
                    <td className="border px-4 py-2">{total}</td>
                    <td className="border px-4 py-2"></td>
                    <td className="border px-4 py-2">{totalAmount.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              <Descriptions
                bordered
                layout="vertical"
                style={{ marginTop: '10px' }}
                title="Car Counts"
              >
                <Descriptions.Item label="System Counts(a)">
                  <Descriptions layout="vertical" bordered>
                    <Descriptions.Item label="b">{session.loopCounts.a}</Descriptions.Item>
                    <Descriptions.Item label="s">{session.loopCounts.b}</Descriptions.Item>
                    <Descriptions.Item label="Average">
                      {Math.round((session.loopCounts.a + session.loopCounts.b) / 2)}
                    </Descriptions.Item>
                  </Descriptions>
                </Descriptions.Item>
                <Descriptions.Item label="Collector's Count(b)">{total}</Descriptions.Item>
                <Descriptions.Item label="Descrepency(a-b)">
                  {Math.round((session.loopCounts.a + session.loopCounts.b) / 2) - total}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      )}
    </SideSheet>
  );
}

export default ViewSession;
