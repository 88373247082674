import React from 'react';

function Header({ title, additional = () => {} }) {
  return (
    <header className="">
      <div style={{ padding: '30px 240px' }}>
        <div>
          <div className="mt-2 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl  font-bold leading-7 text-black sm:text-3xl sm:leading-9 sm:truncate">
                {title}
              </h2>
            </div>
            <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">{additional()}</div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
